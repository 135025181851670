
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import ReportingMessagesCallsTableFilterFull from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsTableFilterFull.vue'
import ReportingCallsTableFull from '@/components/pages/reporting/messagesCalls/calls/ReportingCallsTableFull.vue'
import ReportingCallsStats from '@/components/pages/reporting/messagesCalls/calls/ReportingCallsStats.vue'
import type { ReportingMessagesStatsFullLine } from '@/definitions/reporting/messagesCalls/messages/types'

export default defineComponent({
  components: {
    ReportingMessagesCallsTableFilterFull,
    ReportingCallsTableFull,
    ReportingCallsStats,
  },
  props: {
    statsFull: {
      type: Object as PropType<ReportingMessagesStatsFullLine[]>,
      required: true,
    },
  },
})

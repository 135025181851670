import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.tableHeaders,
    items: _ctx.statsFull,
    "item-key": "year",
    "hide-footer": "",
    class: "mt-4"
  }, {
    "body-cell-year-slot": _withCtx(({ row }) => [
      _createVNode(_component_router_link, {
        to: {
          name: 'base.reporting.calls.year',
          params: {
            year: row.year,
          },
        }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(row.year), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-088ddc13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stats-container" }
const _hoisted_2 = { class: "stats-container__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_calls_stats_filter = _resolveComponent("reporting-calls-stats-filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_reporting_calls_stats_filter),
    _createElementVNode("div", _hoisted_2, " Call forwarding stats by " + _toDisplayString(_ctx.type), 1),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), { class: "mb-3" }))
  ]))
}
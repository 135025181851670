import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_messages_calls_table_filter_full = _resolveComponent("reporting-messages-calls-table-filter-full")!
  const _component_reporting_calls_table_full = _resolveComponent("reporting-calls-table-full")!
  const _component_reporting_calls_stats = _resolveComponent("reporting-calls-stats")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_reporting_messages_calls_table_filter_full),
    _createVNode(_component_reporting_calls_table_full, {
      "hide-actions": "",
      "stats-full": _ctx.statsFull
    }, null, 8, ["stats-full"]),
    _createVNode(_component_reporting_calls_stats, { type: "year" })
  ]))
}